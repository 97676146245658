import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetQuoteData, setEditingQuote } from '../../../redux/actions/actions';
import css from '../index.module.css';
import classnames from 'classnames';
import Button from '../../../components/button/button';
import Spinner from '../../../components/spinner';
import formatter from '../../../utils/formatter';
import SendQuoteModal from '../../viewquote/components/sendquotemodal';
import { useSelectApi } from '../../../hooks/selectApi';
import Pagination from '../../../components/pagination';
import { DefaultPageSizes } from '../../../data/pagination';
import { DateTime } from 'luxon';
import { getDifferenceInDays, isBlank, parseAddress, parseISODate, sumCurrency } from '../../../utils/jsutils';
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import { resetInstructData } from '../../../redux/actions/actions';
import { useEditMapper } from '../utils';
import { QuoteType } from '../../../data/quote-type';

const PreferredQuotes = ({ searchCriteria }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { populateQuoteStore } = useEditMapper();
  const { getSavedQuotes, getInsuranceQuote } = useSelectApi();

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [quotes, setQuotes] = useState(null);
  const [selectedQuoteGroupId, setSelectedQuoteGroupId] = useState();
  const [selectedQuoteIds, setSelectedQuoteIds] = useState();
  const [selectedSaved, setSelectedSaved] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalQuotes, setTotalQuotes] = useState(0);

  const mapQuotes = (quotesReceived) =>
    quotesReceived.items.map((group) => {
      return group.conveyancyQuotes
        .filter((quote) => quote.preferred && filterQuotes(group, quote))
          .map((quote) => ({
          _requestData: group.requestData,
          quoteId: quote.quoteId,
          quoteType: quote.quoteType,
          quoteName: group.quoteName,
          expiryDate: group.expiryDate,
          quoteGroupId: group.id,
          lawFirmName: quote.lawFirm.name,
          totalCost: quote.costSummary.totalCost,
          propertyAddress: group.requestData.filter((item) => item !== null && !equalIgnoreCase(item.quoteType, QuoteType.INSURANCE))[0]?.propertyAddress,
          referralFee: quote.costSummary.referralFee,
          caseId: quote.caseId,
          insuranceReference: group.insuranceDetails?.quoteReference,
          saved: !!group.customerDetails?.forename && !!group.customerDetails?.surname && !!group.customerDetails?.correspondenceAddress
        }))
    }).flat();

  const getQuoteName = ({ quoteName, quoteType, propertyAddress }) => {
    if (quoteName && !isBlank(quoteName)) return quoteName;

    if (!propertyAddress || propertyAddress === undefined || Object.entries(propertyAddress)?.length <= 0) return `${quoteType.toUpperCase()}`;

    const { 
      houseName, houseNumber, streetName, postTown, postcode1, postcode2 
    } = propertyAddress;
    
    const label = parseAddress({
      houseName, houseNumber, streetName, postTown, postcode1, postcode2
    });

    return `${label} - ${quoteType.toUpperCase()}`;
  }

  const combineQuotes = (mappedQuotes) => {
    const result = [];
    mappedQuotes.map((item) => {
      const existing = result.find((i) => i.quoteGroupId == item.quoteGroupId);
      if (existing) {
        existing.quoteType = `${existing.quoteType} AND ${item.quoteType}`
        existing.quoteIds.push(item.quoteId);
        existing.totalCost = sumCurrency(existing.totalCost, item.totalCost);
      } else {
        result.push({
          ...item,
          quoteIds: [item.quoteId]
        })
      };
    })
    return result;
  }

  const filterQuotes = (group, quote) => {
    if (!searchCriteria) return quote;

    const param = searchCriteria.value.toLowerCase();

    switch (searchCriteria.type) {
      case 'lawFirmName':
        return quote.lawFirm.name.toLowerCase().includes(param);
      case 'CorrespondencePostcode':
        return group.requestData.find((item) => `${item.propertyAddress.postcode1}${item.propertyAddress.postcode2}`.toLowerCase().includes(param.replace(' ', '')))
      case 'CustomerSurname':
        return group.customerDetails?.surname.toLowerCase().includes(param);
      default:
        return true;
    }
  }

  useEffect(() => {
    async function load() {
      const quotes = await getSavedQuotes({
        pageSize: DefaultPageSizes[0].value,
        page: currentPage,
        searchCriteria,
        hasPreferred: true
      });
      if (quotes && quotes.items?.length > 0) {
        setQuotes(combineQuotes(mapQuotes(quotes)));
        setTotalQuotes(quotes.totalCount);
      }
      else setQuotes(null);

      setIsLoading(false);
    }
    setIsLoading(true);
    load();
  }, [currentPage, searchCriteria]);

  const handleInstructClick = (e, { quoteGroupId }) => {
    e.preventDefault();
    dispatch(resetInstructData());
    history.push(`/instruct-quote/${quoteGroupId}`)
  }

  const handleViewClick = (e, { quoteGroupId }) => {
    e.preventDefault();
    history.push(`/view-quotes/${quoteGroupId}`);
  };

  const handleSendClick = (e, { quoteGroupId, quoteIds, saved }) => {
    e.preventDefault();
    setSelectedQuoteGroupId(quoteGroupId);
    setSelectedQuoteIds(quoteIds);
    setSelectedSaved(saved);
    setShowModal(true);
  }

  const handleHideSend = () => setShowModal(false);

  const handleEditClick = (e, item) => {
    async function findInsuranceQuote() {
      let insuranceQuote = null;
      if (item.insuranceReference) {
        insuranceQuote = await getInsuranceQuote({ quoteGroupId: item.quoteGroupId, insuranceReference: item.insuranceReference})
      }
      populateQuoteStore({ requestData: item._requestData, insuranceQuote });
      dispatch(setEditingQuote());
      history.push('/quote');
      setIsLoading(false);
    }

    e.preventDefault();
    setIsLoading(true);
    dispatch(resetQuoteData())
    findInsuranceQuote();
  }

  const getExpiry = (expiry) => {
    const expiryDate = parseISODate(expiry);
    const currentDate = DateTime.now().toUTC();
    const expired = expiryDate < currentDate;
    return {
      expired,
      daysLeft: getDifferenceInDays(expiryDate, currentDate)
    };
  }

  const getExpiryString = (expired, daysLeft) => {
    if (expired) return 'expired';

    return daysLeft > 1
      ? `${daysLeft} days left`
      : `${daysLeft} day left`;
  }

  const renderQuoteItem = (item, idx) => {
    const { expired, daysLeft } = getExpiry(item.expiryDate);

    return (
      <tr key={`quotes-table-${idx}`} className={css.tableRow}>
        <td className={classnames(css.td, css.quoteName)} data-testid="quote-name">{getQuoteName(item)}</td>
        <td className={classnames(css.td, css.quoteFirm)} data-testid="law-firm-name">{item.lawFirmName}</td>
        <td className={classnames(css.td, css.quotePrice)} data-testid="total-cost">{formatter.formatCurrency(item.totalCost)}</td>
        <td className={classnames(css.td, css.quoteFee)} data-testid="broker-fee">{formatter.formatCurrency(item.referralFee)}</td>
        <td className={classnames(css.td, css.expiry)} data-testid="quote-expiry">{`${getExpiryString(expired, daysLeft)}`}</td>
        <td className={css.td}>
          <div className={css.buttonCell}>
            <Button
              btnClass={css.button}
              id="quotes-table-view-button"
              datatestid="quotes-table-view-button"
              variant="tertiary"
              text="VIEW"
              onClick={(e) => handleViewClick(e, item)}
              size="md"
            />
            <Button
              btnClass={css.button}
              id="quotes-table-edit-button"
              datatestid="quotes-table-edit-button"
              variant="tertiary"
              text="EDIT"
              size="md"
              onClick={(e) => handleEditClick(e, item)}
            />
            <Button
              btnClass={css.button}
              id="quotes-table-send-button"
              datatestid="quotes-table-send-button"
              variant="tertiary"
              text="SEND"
              disabled={expired}
              size="md"
              onClick={(e) => handleSendClick(e, item)}
            />
            <Button
              btnClass={css.button}
              id="quotes-table-instruct-button"
              datatestid="quotes-table-instruct-button"
              variant="tertiary"
              text="INSTRUCT"
              disabled={!!item.caseId || expired}
              size="md"
              onClick={(e) => handleInstructClick(e, item)}
            />
          </div>
        </td>
      </tr>
    )
  }

  const renderTableBody = (quotes) => {
    if (!quotes || quotes.length === 0) {
      return (<>
        <tr id="quotes-table-no-results" className={css.tableRow}>
          <td className={css.td}>No matching quotes found</td>
        </tr>
      </>
      );
    }

    return quotes.map((c, i) => renderQuoteItem(c, i));
  }

  return (<>
    <table className={css.table}>
      <thead>
        <tr>
          <th colSpan="6"><span className={css.tableHeader}>Saved quotes - firm selected</span></th>
        </tr>
        <tr>
          <th colSpan="6">
            <div className={css.paginationContainer}>
              <Pagination
                className={css.bottompad1x}
                currentPage={currentPage}
                totalCount={totalQuotes ?? 0}
                pageSize={DefaultPageSizes[0].value}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className={css.tableRow}>
          <th className={classnames(css.td, css.columnHeader)}>Quote Name</th>
          <th className={classnames(css.td, css.columnHeader)}>Law firm</th>
          <th className={classnames(css.td, css.columnHeader)}>Price</th>
          <th className={classnames(css.td, css.columnHeader)}>Referral fee</th>
          <th className={classnames(css.td, css.columnHeader)}>Expiry</th>
          <th className={classnames(css.td, css.columnHeader)}>Action</th>
        </tr>
        {!isLoading ? renderTableBody(quotes)
          :
          <tr>
            <td colSpan="6" className={css.loadingCell}>
              <div className={css.loading}>
                <Spinner variant={css.spinner} /> Loading quotes...
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
    <SendQuoteModal
    show={showModal}
    onHide={handleHideSend}
    actionButtonText="SEND QUOTE"
    quoteGroupId={selectedQuoteGroupId}
    quoteIds={selectedQuoteIds}
    requiresSave={!selectedSaved}
  />
  </>
  )

}

export default PreferredQuotes