import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./index.module.scss";
import classnames from "classnames";

import AuthCheck from "../../../components/authcheck/authCheck";
import authState from "../../../components/authcheck/authState";
import Layout from "../../../layout";
import Table from "../components/action-table";
import useSelectApi from "../../../hooks/useSelectApi";
import { IProduct } from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Button from "../../../components/button/button";
import Breadcrumb from "../components/breadcrumbs";

function Products() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const { getProducts } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  const breadcrumbs = [
    { name: "Home", path: "/" },
    { name: "Products and Pricing", isActive: true },
  ];

  const handleManageProductPricingClick = (productId: string) => {
    console.log('unsupported')
  };

  const renderCell = (row: IProduct, header: string) => {
    switch (header) {
      case "PRODUCT":
        return row.name;
      case "DATE ADDED":
        return row.createdOn;
      default:
        return "";
    }
  };

  const renderActions = (row: IProduct) => (
    <div>
      <Button
        id={row.id}
        datatestid={row.id}
        btnClass={classnames(css.actionButton)}
        onClick={() => handleManageProductPricingClick(row.id)}
        type="button"
        text="MANAGE PRICING"
      />
    </div>
  );

  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true);

      const apiPage = await getProducts();

      if (!apiPage.success) {
        return [];
      } else {
        setProducts(apiPage.data!);
      }

      setIsLoading(false);
    };

    loadProducts();
  }, []);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      {isLoading && <Loading />}
      {!isLoading && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Breadcrumb items={breadcrumbs} />
              <Col md={12} xs={12}>
                <div className={css.header}>YOUR OFFICES</div>
              </Col>
            </Row>

            <Row className={css.tableContainer}>
              <Table
                data={products}
                noData="Your firm currently has no products assigned"
                headers={["PRODUCT", "DATE ADDED", "PRICING UPDATED" ]}
                renderCell={renderCell}
                renderActions={renderActions}
              />
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      )}
    </Layout>
  );
}

export default Products;
