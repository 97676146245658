import React from "react";
import css from "./Footer.module.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Footer = () => {
  return (
    <footer id="footer" data-testid="footer" className={css.footer}>
      <Container fluid>
        <Row className={css.bottompad2x}>
          <Col md={1} xs={12}></Col>
          <Col md={7} xs={12}>
            <span className={css.copyright}>
              Copyright &#169; LMS {new Date().getFullYear()}
            </span>
            <a
              data-testid="terms-of-use"
              href={window?.config?.termsOfUse}
              rel="noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
            <span className={css.pipe}></span>
            <a
              data-testid="privacy-policy"
              href={window?.config?.privacyPolicy}
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>

            {process.env.REACT_APP_VERSION && (
              <>
                <span className={css.pipe}></span>
                <span>{process.env.REACT_APP_VERSION}</span>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
