import {
  STORE_QUOTE_TYPE,
  GET_SETTINGS,
  GET_LENDERS,
  GET_PANELS,
  SITE_BUSY,
  STORE_QUOTE_PANEL,
  STORE_QUOTE_REGION,
  STORE_QUOTE_PROPERTY_ADDRESS,
  STORE_QUOTE_TRANSACTION_AMOUNT,
  STORE_QUOTE_TENURE,
  STORE_QUOTE_MORTGAGE,
  STORE_QUOTE_OBTAIN_MORTGAGE,
  STORE_QUOTE_LTD_COMPANY,
  STORE_QUOTE_TRANSFER_REQUIRED,
  STORE_QUOTE_FIRST_TIME_BUYER,
  STORE_QUOTE_FIRST_REGISTRATION_TRAIT,
  STORE_QUOTE_MAIN_RESIDENCE,
  STORE_QUOTE_OTHER_RESIDENCE,
  STORE_QUOTE_NEW_LENDER,
  STORE_QUOTE_OWNERS,
  STORE_QUOTE_OWNERS_AFTER_TRANSFER,
  STORE_QUOTE_TRAITS,
  STORE_QUOTE_ARMED_FORCES_TRAIT,
  STORE_QUOTE_AUCTION_TRAIT,
  STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT,
  STORE_QUOTE_EXPATS_TRAIT,
  STORE_QUOTE_NUMBER_OF_EXPATS,
  STORE_QUOTE_GIFTED_DEPOSIT_TRAIT,
  STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS,
  STORE_QUOTE_GIFTED_EQUITY_TRAIT,
  STORE_QUOTE_HELP_TO_BUY_TRAIT,
  STORE_QUOTE_NUMBER_OF_HELP_TO_BUY,
  STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT,
  STORE_QUOTE_HMO_TRAIT,
  STORE_QUOTE_STUDENT_PROPERTY,
  STORE_QUOTE_JOINT_PROPRIETOR_TRAIT,
  STORE_QUOTE_SOLE_PROPRIETOR_TRAIT,
  STORE_QUOTE_NEW_BUILD_TRAIT,
  STORE_QUOTE_RIGHT_TO_BUY_TRAIT,
  STORE_QUOTE_SHARED_OWNERSHIP_TRAIT,
  STORE_QUOTE_HIGH_RISE_FLAT_TRAIT,
  STORE_QUOTE_PROPERTY_TYPE,
  STORE_QUOTE_CUSTOMER_LOCATION,
  STORE_QUOTE_BESPOKE_REFERRAL_FEE,
  STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE,
  STORE_QUOTE_YEAR_BUILT,
  STORE_QUOTE_NUMBER_OF_BEDROOMS,
  STORE_QUOTE_BRIDGING_FINANCE,
  STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION,
  SUBMIT_QUOTE,
  VALIDATE_QUOTE,
  VALIDATE_BESPOKE_REFERRAL_FEE,
  STORE_PREFERENCE_DEFAULT_REFERRAL_FEE,
  STORE_PREFERENCE_CONTACT_EMAIL,
  STORE_PREFERENCE_CONTACT_MOBILE,
  STORE_PREFERENCE_DEFAULT_JURISDICTION,
  STORE_PREFERENCE_UINSURE_QUOTING_TYPE,
  STORE_PREFERENCE_CASE_UPDATE_TYPE,
  STORE_PREFERENCE_PREFERRED_PRICING_MODEL,
  STORE_PREFERENCE_PREFERRED_SORT_ORDER,
  STORE_PREFERENCE_LAST_UPDATED,
  STORE_PREFERENCE_NETWORK,
  VALIDATE_PREFERENCES,
  GET_USER_SETTINGS,
  STORE_DATE_OF_BIRTH,
  RESET_QUOTE_DATA,
  RESET_INSTRUCT_DATA,
  LINK_UINSURE,
  STORE_UINSURE_VALIDATION,
  UINSURE_LINK_COMPLETE,
  STORE_UINSURE_QUOTE,
  GET_UINSURE_QUOTE,
  STORE_UINSURE_SELECTED_QUOTE,
  GET_UINSURE_REDIRECT,
  STORE_UINSURE_INSTRUCT,
  VALIDATE_UINSURE_FORM,
  STORE_UINSURE_VALIDATION_SUCCESS,
  RESET_STORE,
  GET_CURRENT_QUOTE_FROM_SAVED,
  SET_QUOTE_EDITING,
  SET_SELECTED_QUOTES,
} from "./types";

export function validatePreferences() {
  return {
    type: VALIDATE_PREFERENCES,
  };
}

export function storePreferenceDefaultReferralFee(fee, storePath) {
  return {
    type: STORE_PREFERENCE_DEFAULT_REFERRAL_FEE,
    payload: fee,
    storePath,
  };
}

export function storePreferenceContactEmail(email, storePath) {
  return {
    type: STORE_PREFERENCE_CONTACT_EMAIL,
    payload: email,
    storePath,
  };
}

export function storePreferenceContactMobile(mobile, storePath) {
  return {
    type: STORE_PREFERENCE_CONTACT_MOBILE,
    payload: mobile,
    storePath,
  };
}

export function storePreferenceJurisdiction(jurisdiction, storePath) {
  return {
    type: STORE_PREFERENCE_DEFAULT_JURISDICTION,
    payload: jurisdiction,
    storePath,
  };
}

export function storePreferenceUinsureQuotingType(quotingtype, storePath) {
  return {
    type: STORE_PREFERENCE_UINSURE_QUOTING_TYPE,
    payload: quotingtype,
    storePath,
  };
}

export function storePreferenceCaseUpdateType(updatetype, storePath) {
  return {
    type: STORE_PREFERENCE_CASE_UPDATE_TYPE,
    payload: updatetype,
    storePath,
  };
}

export function storePreferencePreferredPricingModel(
  preferredpricingmodel,
  storePath
) {
  return {
    type: STORE_PREFERENCE_PREFERRED_PRICING_MODEL,
    payload: preferredpricingmodel,
    storePath,
  };
}

export function storePreferencePreferredSortOrder(
  preferredsortorder,
  storePath
) {
  return {
    type: STORE_PREFERENCE_PREFERRED_SORT_ORDER,
    payload: preferredsortorder,
    storePath,
  };
}

export function storePreferenceLastUpdated(lastUpdated) {
  return {
    type: STORE_PREFERENCE_LAST_UPDATED,
    payload: lastUpdated,
  };
}

export function storePreferenceNetwork(network, storePath) {
  return {
    type: STORE_PREFERENCE_NETWORK,
    payload: network,
    storePath,
  };
}

export function storeQuoteType(type, storePath) {
  return {
    type: STORE_QUOTE_TYPE,
    payload: type,
    storePath,
  };
}

export function storeQuoteRegion(region, storePath) {
  return {
    type: STORE_QUOTE_REGION,
    payload: region,
    storePath,
  };
}

export function storeQuotePanel(panelId, storePath) {
  return {
    type: STORE_QUOTE_PANEL,
    payload: panelId,
    storePath,
  };
}

export function storeQuotePropertyAddress(propertyAddress, storePath) {
  return {
    type: STORE_QUOTE_PROPERTY_ADDRESS,
    payload: propertyAddress,
    storePath,
  };
}

export function storeQuotePropertyType(propertyType, storePath) {
  return {
    type: STORE_QUOTE_PROPERTY_TYPE,
    payload: propertyType,
    storePath,
  };
}

export function storeQuoteCustomerLocation(customerLocation, storePath) {
  return {
    type: STORE_QUOTE_CUSTOMER_LOCATION,
    payload: customerLocation,
    storePath,
  };
}

export function storeQuoteTransactionAmount(transactionAmount, storePath) {
  return {
    type: STORE_QUOTE_TRANSACTION_AMOUNT,
    payload: transactionAmount,
    storePath,
  };
}

export function storeQuoteTenure(tenure, storePath) {
  return {
    type: STORE_QUOTE_TENURE,
    payload: tenure,
    storePath,
  };
}

export function storeQuoteMortgage(mortgage, storePath) {
  return {
    type: STORE_QUOTE_MORTGAGE,
    payload: mortgage,
    storePath,
  };
}

export function storeQuoteObtainMortgage(obtainmortgage, storePath) {
  return {
    type: STORE_QUOTE_OBTAIN_MORTGAGE,
    payload: obtainmortgage,
    storePath,
  };
}

export function storeQuoteLtdCompany(ltdcompany, storePath) {
  return {
    type: STORE_QUOTE_LTD_COMPANY,
    payload: ltdcompany,
    storePath,
  };
}

export function storeQuoteFirstTimeBuyer(firsttimebuyer, storePath) {
  return {
    type: STORE_QUOTE_FIRST_TIME_BUYER,
    payload: firsttimebuyer,
    storePath,
  };
}

export function storeQuoteFirstRegistrationTrait(firstregistrationtrait, storePath) {
  return {
    type: STORE_QUOTE_FIRST_REGISTRATION_TRAIT,
    payload: firstregistrationtrait,
    storePath,
  };
}

export function storeQuoteTransferRequired(transferrequired, storePath) {
  return {
    type: STORE_QUOTE_TRANSFER_REQUIRED,
    payload: transferrequired,
    storePath,
  };
}

export function storeQuoteMainResidence(mainresidence, storePath) {
  return {
    type: STORE_QUOTE_MAIN_RESIDENCE,
    payload: mainresidence,
    storePath,
  };
}

export function storeQuoteOtherResidence(otherresidence, storePath) {
  return {
    type: STORE_QUOTE_OTHER_RESIDENCE,
    payload: otherresidence,
    storePath,
  };
}

export function storeQuoteOwners(owners, storePath) {
  return {
    type: STORE_QUOTE_OWNERS,
    payload: owners,
    storePath,
  };
}

export function storeQuoteOwnersAfterTransfer(ownersaftertransfer, storePath) {
  return {
    type: STORE_QUOTE_OWNERS_AFTER_TRANSFER,
    payload: ownersaftertransfer,
    storePath,
  };
}

export function storeQuoteNewLender(newlender, storePath) {
  return {
    type: STORE_QUOTE_NEW_LENDER,
    payload: newlender,
    storePath,
  };
}

export function storeQuoteTraits(traits, storePath) {
  return {
    type: STORE_QUOTE_TRAITS,
    payload: traits,
    storePath,
  };
}

export function storeQuoteArmedForcesTrait(armedforcestrait, storePath) {
  return {
    type: STORE_QUOTE_ARMED_FORCES_TRAIT,
    payload: armedforcestrait,
    storePath,
  };
}

export function storeQuoteAuctionTrait(auctiontrait, storePath) {
  return {
    type: STORE_QUOTE_AUCTION_TRAIT,
    payload: auctiontrait,
    storePath,
  };
}

export function storeQuoteDeedOfPostponementTrait(
  deedofpostponementtrait,
  storePath
) {
  return {
    type: STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT,
    payload: deedofpostponementtrait,
    storePath,
  };
}

export function storeQuoteExpatsTrait(expatstrait, storePath) {
  return {
    type: STORE_QUOTE_EXPATS_TRAIT,
    payload: expatstrait,
    storePath,
  };
}

export function storeQuoteNumberOfExpats(numberofexpats, storePath) {
  return {
    type: STORE_QUOTE_NUMBER_OF_EXPATS,
    payload: numberofexpats,
    storePath,
  };
}

export function storeQuoteGiftedDepositTrait(gifteddeposittrait, storePath) {
  return {
    type: STORE_QUOTE_GIFTED_DEPOSIT_TRAIT,
    payload: gifteddeposittrait,
    storePath,
  };
}

export function storeQuoteNumberOfGiftedDeposits(
  numberofgifteddeposits,
  storePath
) {
  return {
    type: STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS,
    payload: numberofgifteddeposits,
    storePath,
  };
}

export function storeQuoteGiftedEquityTrait(giftedequitytrait, storePath) {
  return {
    type: STORE_QUOTE_GIFTED_EQUITY_TRAIT,
    payload: giftedequitytrait,
    storePath,
  };
}

export function storeQuoteHelpToBuyTrait(helptobuytrait, storePath) {
  return {
    type: STORE_QUOTE_HELP_TO_BUY_TRAIT,
    payload: helptobuytrait,
    storePath,
  };
}

export function storeQuoteNumberOfHelpToBuy(numberofhelptobuy, storePath) {
  return {
    type: STORE_QUOTE_NUMBER_OF_HELP_TO_BUY,
    payload: numberofhelptobuy,
    storePath,
  };
}

export function storeQuoteHelpToBuyLoanTrait(helptobuyloantrait, storePath) {
  return {
    type: STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT,
    payload: helptobuyloantrait,
    storePath,
  };
}

export function storeQuoteHMOTrait(hmotrait, storePath) {
  return {
    type: STORE_QUOTE_HMO_TRAIT,
    payload: hmotrait,
    storePath,
  };
}

export function storeQuoteStudentProperty(studentproperty, storePath) {
  return {
    type: STORE_QUOTE_STUDENT_PROPERTY,
    payload: studentproperty,
    storePath,
  };
}

export function storeQuoteJointProprietorTrait(
  jointproprietortrait,
  storePath
) {
  return {
    type: STORE_QUOTE_JOINT_PROPRIETOR_TRAIT,
    payload: jointproprietortrait,
    storePath,
  };
}

export function storeQuoteSoleProprietorTrait(
  soleproprietortrait,
  storePath
) {
  return {
    type: STORE_QUOTE_SOLE_PROPRIETOR_TRAIT,
    payload: soleproprietortrait,
    storePath,
  };
}

export function storeQuoteNewBuildTrait(newbuildtrait, storePath) {
  return {
    type: STORE_QUOTE_NEW_BUILD_TRAIT,
    payload: newbuildtrait,
    storePath,
  };
}

export function storeQuoteRightToBuyTrait(righttobuytrait, storePath) {
  return {
    type: STORE_QUOTE_RIGHT_TO_BUY_TRAIT,
    payload: righttobuytrait,
    storePath,
  };
}

export function storeQuoteSharedOwnershipTrait(
  sharedownershiptrait,
  storePath
) {
  return {
    type: STORE_QUOTE_SHARED_OWNERSHIP_TRAIT,
    payload: sharedownershiptrait,
    storePath,
  };
}

export function storeQuoteHighRiseFlatTrait(
  highriseflattrait,
  storePath
) {
  return {
    type: STORE_QUOTE_HIGH_RISE_FLAT_TRAIT,
    payload: highriseflattrait,
    storePath,
  };
}

export function storeQuoteBespokeReferralFee(bespokeReferralFee, storePath) {
  return {
    type: STORE_QUOTE_BESPOKE_REFERRAL_FEE,
    payload: bespokeReferralFee,
    storePath,
  };
}

export function storeQuoteUpdateDefaultReferralFee(
  updateDefaultReferralFee,
  storePath
) {
  return {
    type: STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE,
    payload: updateDefaultReferralFee,
    storePath,
  };
}

export function storeQuoteNumberOfBedrooms(payload, storePath) {
  return {
    type: STORE_QUOTE_NUMBER_OF_BEDROOMS,
    payload,
    storePath
  }
}

export function storeQuoteYearBuilt(payload, storePath) {
  return {
    type: STORE_QUOTE_YEAR_BUILT,
    payload,
    storePath
  }
}

export function getSettings() {
  return {
    type: GET_SETTINGS,
  };
}

export function getLenders() {
  return {
    type: GET_LENDERS,
  };
}

export function getPanels(searchTerm) {
  return {
    type: GET_PANELS,
    payload: searchTerm
  };
}

export function getUserSettings(username) {
  return {
    type: GET_USER_SETTINGS,
    payload: username,
  };
}

export function submitQuote() {
  return {
    type: SUBMIT_QUOTE,
  };
}

export function validateQuote(history, send) {
  return {
    type: VALIDATE_QUOTE,
    ...history,
    send,
  };
}

export function validateBespokeReferralFee(payload) {
  return {
    type: VALIDATE_BESPOKE_REFERRAL_FEE,
    payload,
  };
}

export function storeDateOfBirth(payload, storePath) {
  return {
    type: STORE_DATE_OF_BIRTH,
    payload,
    storePath,
  };
}

export function resetQuoteData() {
  return {
    type: RESET_QUOTE_DATA,
  };
}

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}

export function resetInstructData() {
  return {
    type: RESET_INSTRUCT_DATA,
  };
}

export function siteBusy(payload) {
  return {
    type: SITE_BUSY,
    payload,
  };
}

export function linkUinsure(payload) {
  return {
    type: LINK_UINSURE,
    payload,
  };
}

export function storeUinsureValidation(payload) {
  return {
    type: STORE_UINSURE_VALIDATION,
    payload,
  };
}

/**
 * @param {boolean} isLinkComplete
 */
export function uinsureLinkComplete(isLinkComplete) {
  return {
    type: UINSURE_LINK_COMPLETE,
    isLinkComplete,
  };
}

export function getUinsureQuote({ quoteGroupId }) {
  return {
    type: GET_UINSURE_QUOTE,
    quoteGroupId,
  };
}

export function storeUinsureQuote(payload) {
  return {
    type: STORE_UINSURE_QUOTE,
    payload,
  };
}

export function storeUinsureSelectedQuote(payload) {
  return {
    type: STORE_UINSURE_SELECTED_QUOTE,
    payload,
  };
}

export function getUinsureRedirect() {
  return {
    type: GET_UINSURE_REDIRECT,
  };
}

export function storeUinsureInstruct(payload) {
  return {
    type: STORE_UINSURE_INSTRUCT,
    payload,
  };
}

export function validateUinsureForm() {
  return {
    type: VALIDATE_UINSURE_FORM,
  };
}

export function validateUinsureFormSuccess(payload) {
  return {
    type: STORE_UINSURE_VALIDATION_SUCCESS,
    payload,
  };
}

export function getCurrentQuoteFromSaved(history, quoteId, location) {
  return {
    type: GET_CURRENT_QUOTE_FROM_SAVED,
    history,
    quoteId,
    location
  }
}

export function setEditingQuote() {
  return {
    type: SET_QUOTE_EDITING
  }
}

export function setSelectedQuotes(quoteIds) {
  return {
    type: SET_SELECTED_QUOTES,
    quoteIds
  }
}

export function storeQuoteBridgingFinance(bridgingfinance, storePath) {
  return {
    type: STORE_QUOTE_BRIDGING_FINANCE,
    payload: bridgingfinance,
    storePath,
  };
}

export function storeQuoteBridgingFinanceRepresentation(bridgingfinancerepresentation, storePath) {
  return {
    type: STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION,
    payload: bridgingfinancerepresentation,
    storePath,
  };
}
