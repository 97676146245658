import { createStore, applyMiddleware, compose } from 'redux';
import persistStore from 'redux-persist/es/persistStore';
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import sagas from './sagas'
import { useSelector, type TypedUseSelectorHook } from 'react-redux';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

export const persistor = persistStore(store);

sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useSelector`
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store;
