import React, { useEffect, useState } from 'react';
import AuthCheck from '../../components/authcheck/authCheck';
import authState from '../../components/authcheck/authState';
import { Accordion, Row, Col } from 'react-bootstrap';
import css from './index.module.css';
import Spinner from '../../components/spinner';
import SearchBar from '../../components/SearchBar';
import displayDropdownItems from './data';
import AccordionItemWrapper from "../../components/AccordionItems/AccordionItemWrapper";
import AccordionItemContent from './components/AccordionItemContent';
import { useSelectApi } from '../../hooks/selectApi';
import Pagination from '../../components/pagination';
import { DefaultPageSizes } from '../../data/pagination';
import classnames from "classnames";
import Layout from '../../layout';

const CaseProgress = () => {
  const { getActiveCases, getClosedCases } = useSelectApi();
  const [activeCases, setActiveCases] = useState();
  const [closedCases, setClosedCases] = useState();
  const [activeLoading, setActiveLoading] = useState(true);
  const [closedLoading, setClosedLoading] = useState(true);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentClosedPage, setCurrentClosedPage] = useState(1);
  const [currentSearch, setCurrentSearch] = useState();

  useEffect(() => {
    async function retrieveCases() {
      const activeResult = await getActiveCases({ pageSize: DefaultPageSizes[0].value, page: currentActivePage, searchCriteria: currentSearch });
      setActiveCases(activeResult);
      setActiveLoading(false);
    }
    setActiveLoading(true);
    retrieveCases();
  }, [currentActivePage, currentSearch])


  useEffect(() => {
    async function retrieveCases() {
      const activeResult = await getClosedCases({ pageSize: DefaultPageSizes[0].value, page: currentClosedPage, searchCriteria: currentSearch });
      setClosedCases(activeResult);
      setClosedLoading(false);
    }
    setClosedLoading(true);
    retrieveCases();

  }, [currentClosedPage, currentSearch]);

  if (activeLoading && closedLoading) {
    return (
      <Layout>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <h1 className={css.title}>Your cases</h1>
            <SearchBar
              type="cases"
              placeholder="Find a saved case - Search postcode, name or law firm..."
              displayDropdownItems={displayDropdownItems}
              onChange={setCurrentSearch}
            />
            <div className={css.loading}>
              <Spinner variant={css.spinner} /> Loading cases...
            </div>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    )
  }
  if (activeCases?.items?.length === 0 && closedCases?.items?.length === 0) {
    return (
      <Layout>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <h1 className={css.title}>Your cases</h1>
            <SearchBar
              type="cases"
              placeholder="Find a saved case - Search postcode, name or law firm..."
              displayDropdownItems={displayDropdownItems}
              onChange={setCurrentSearch}
            />
            <span>No cases found</span>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      </Layout>
    );
  }
  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row className={css.bottompad2x}>
        <Col md={1} xs={1}></Col>
        <Col md={10} xs={10}>
          <h1 className={css.title}>Your cases</h1>
          <SearchBar
            type="cases"
            placeholder="Find a saved case - Search postcode, name or law firm..."
            displayDropdownItems={displayDropdownItems}
            onChange={setCurrentSearch}
          />
          <Accordion defaultActiveKey="1" className={classnames(css.accordion, css.bottompad2x)} >
            <div className={classnames(css.paginationContainer, css.bottompad1x)}>
              <Pagination
                currentPage={currentActivePage}
                totalCount={activeCases?.totalCount ?? 0}
                pageSize={DefaultPageSizes[0].value}
                onPageChange={setCurrentActivePage}
              />
            </div>
            <AccordionItemWrapper
              id="activeCases"
              cardClassName={css.card}
              headerClassName={css.headerText}
              headerText="ACTIVE CASES"
              eventKey="1"
              lengthToDisplay={activeCases?.totalCount ?? 0}
              variant="cases"
            >
              {activeLoading ? <div className={css.spinnerContainer}><Spinner variant={css.spinner} /></div> :
                activeCases?.items?.map((item, index) => (
                  <AccordionItemContent
                    key={item.caseId}
                    item={item}
                    index={index}
                    eventKey="1"
                  />
                ))}
            </AccordionItemWrapper>

            <hr />

            <div className={classnames(css.paginationContainer, css.bottompad1x)}>
              <Pagination
                currentPage={currentClosedPage}
                totalCount={closedCases?.totalCount ?? 0}
                pageSize={DefaultPageSizes[0].value}
                onPageChange={setCurrentClosedPage}
              />
            </div>
            <AccordionItemWrapper
              id="closedCases"
              cardClassName={css.card}
              headerText="CLOSED CASES"
              eventKey="2"
              headerClassName={css.headerText}
              lengthToDisplay={closedCases?.totalCount ?? 0}
              variant="cases"
            >
              {closedLoading ? <div className={css.spinnerContainer}><Spinner variant={css.spinner} /></div> :
                closedCases?.items?.map((item, index) => (
                  <AccordionItemContent
                    key={item.caseId}
                    item={item}
                    index={index}
                    eventKey="2"
                  />
                ))}
            </AccordionItemWrapper>
          </Accordion>
        </Col>
        <Col md={1} xs={1}></Col>
      </Row>
    </Layout>
  )
}

export default CaseProgress