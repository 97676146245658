import React from "react";
import css from "./index.module.scss";
import ActionButton from "../components/action-button";
import { ReactComponent as AttributesIcon } from "../../../components/shared/imgs/attributes.svg";
import { ReactComponent as PricingIcon } from "../../../components/shared/imgs/lawfirm-pricing-icon.svg";
import { ReactComponent as PanelMembershipIcon } from "../../../components/shared/imgs/panel-membership.svg";
import { useHistory } from "react-router-dom";

const LawFirmDashboard = () => {
  const history = useHistory()

  const handleClick = (location: string) => {
    history.push(location)
  };

  return (
    <div className={css.container}>
      <div className={css.item}>
        <ActionButton
          title="MANAGE YOUR PRODUCTS AND PRICING"
          onClick={() => handleClick("/manage-products")}
          description="Tailor your pricing per panel for enhanced control"
          borderColor="var(--purple)"
          icon={PricingIcon}
        />
      </div>
      <div className={css.item}>
        <ActionButton
          title="MANAGE YOUR OFFICES"
          onClick={() => handleClick("/manage-offices")}
          description="Manage your offices"
          borderColor="var(--light-blue)"
          icon={AttributesIcon}
        />
      </div>
      <div className={css.item}>
        <ActionButton
          title="MANAGE YOUR PANEL MEMBERSHIPS"
          onClick={() => handleClick("/manage-panels")}
          description="Manage your panel memberships"
          borderColor="var(--pink)"
          icon={PanelMembershipIcon}
        />
      </div>
    </div>
  );
};

export default LawFirmDashboard;
